export const msalConfig = {
  auth: {
    clientId: "544a5b00-288b-462e-be3f-f6e133e05fa8", 
    authority: "https://login.microsoftonline.com/e9fe966c-e2dc-42e9-a7f1-29a435c46da8", 
    redirectUri: "https://khoan.net/home", 
  },
  cache: {
    cacheLocation: "localStorage", 
    storeAuthStateInCookie: true,  
  }
};

export const loginRequest = {
  scopes: ["openid", "profile", "email"], 
};
// import logo from './assets/images/logo.svg';
import './assets/styles/App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Login from './components/pages/Login';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './msalConfig';

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={
          <MsalProvider instance={msalInstance}>
            <Login />
          </MsalProvider>} />
        <Route path="/home" element={<Home/>} />
      </Routes>
    </Router>
  );
}

export default App;
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../msalConfig';
import '../../assets/styles/Login.css';

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <div className="login-container">
      <h2>Application Communication</h2>
      <button onClick={handleLogin}>Login with SAML</button>
    </div>
  );
};

export default Login;